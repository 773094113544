var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-box"},[_c('aRow',_vm._l((_vm.menu),function(item,index){return _c('aCol',{key:index,attrs:{"span":24}},[(item.showItem)?_c('div',{staticClass:"row border c-pointer hover",class:`${item.extraClass} ${
            item.disabled ? 'disabled grayscale' : ''
          }`,on:{"click":function($event){return _vm.activeTab(item.name)}}},[_c('span',{staticClass:"txt"},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_c('span',{staticClass:"img"},[(item.img)?_c('img',{attrs:{"src":item.img,"alt":item.name,"width":"15"}}):_vm._e(),_vm._v(" "),(item.ico)?_c('a-icon',{attrs:{"type":item.ico}}):_vm._e(),_vm._v(" "),(item.faIco)?_c('i',{staticClass:"fa-ico",class:item.faIco}):_vm._e()],1),_vm._v("\n                "+_vm._s(item.name)+"\n              ")]),_vm._v(" "),(
                  item.name == 'Dados Pessoais' &&
                  _vm.errorLog.customerData.length > 0
                )?_c('aCol',[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("\n                    "+_vm._s(item.alertMsg)+"\n                  ")]),_vm._v(" "),_c('img',{staticClass:"c-pointer",attrs:{"src":require("@/assets/images/dashboard/contracts/danger.png"),"alt":"img","width":"15"}})],2)],1):_vm._e()],1)],1)]):_vm._e()])}),1)],1),_vm._v(" "),_c('div',{staticClass:"submenu"},[_c('aRow',_vm._l((_vm.submenu),function(item,index){return _c('aCol',{key:index,attrs:{"span":24}},[(item.showItem)?_c('div',{staticClass:"row border c-pointer hover",class:`${item.extraClass} ${
            item.disabled ? 'disabled grayscale' : ''
          }`,on:{"click":function($event){return _vm.activeTab(item.name)}}},[_c('span',{staticClass:"txt"},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_c('span',{staticClass:"img"},[_c('img',{attrs:{"src":item.img,"alt":item.name,"width":"15"}})]),_vm._v("\n                "+_vm._s(item.name)+"\n              ")]),_vm._v(" "),_c('aCol')],1)],1)]):_vm._e()])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }