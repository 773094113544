<template>
  <div>
    <div class="w-box">
      <aRow>
        <aCol v-for="(item, index) in menu" :key="index" :span="24">
          <div
            v-if="item.showItem"
            class="row border c-pointer hover"
            :class="`${item.extraClass} ${
              item.disabled ? 'disabled grayscale' : ''
            }`"
            @click="activeTab(item.name)"
          >
            <span class="txt">
              <aRow type="flex" justify="space-between">
                <aCol>
                  <span class="img">
                    <img
                      v-if="item.img"
                      :src="item.img"
                      :alt="item.name"
                      width="15"
                    />
                    <a-icon v-if="item.ico" :type="item.ico" />
                    <i v-if="item.faIco" class="fa-ico" :class="item.faIco"></i>
                  </span>
                  {{ item.name }}
                </aCol>
                <aCol
                  v-if="
                    item.name == 'Dados Pessoais' &&
                    errorLog.customerData.length > 0
                  "
                >
                  <a-tooltip>
                    <template slot="title">
                      {{ item.alertMsg }}
                    </template>
                    <img
                      class="c-pointer"
                      src="@/assets/images/dashboard/contracts/danger.png"
                      alt="img"
                      width="15"
                    />
                  </a-tooltip>
                </aCol>
              </aRow>
            </span>
          </div>
        </aCol>
      </aRow>
    </div>

    <div class="submenu">
      <aRow>
        <aCol v-for="(item, index) in submenu" :key="index" :span="24">
          <div
            v-if="item.showItem"
            class="row border c-pointer hover"
            :class="`${item.extraClass} ${
              item.disabled ? 'disabled grayscale' : ''
            }`"
            @click="activeTab(item.name)"
          >
            <span class="txt">
              <aRow type="flex" justify="space-between">
                <aCol>
                  <span class="img">
                    <img :src="item.img" :alt="item.name" width="15" />
                  </span>
                  {{ item.name }}
                </aCol>
                <aCol> </aCol>
              </aRow>
            </span>
          </div>
        </aCol>
      </aRow>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

// images
import user from "@/assets/images/dashboard/customers/user.png";
import opportunity from "@/assets/images/dashboard/customers/opportunity.png";
import travels from "@/assets/images/dashboard/customers/travels.png";
import creditLetter from "@/assets/images/dashboard/customers/credit-letter.png";

import wallet from "@/assets/images/dashboard/customers/wallet.png";
import cases from "@/assets/images/dashboard/customers/cases.png";
import note from "@/assets/images/dashboard/customers/note.png";
import settings from "@/assets/images/dashboard/customers/settings.png";
import login from "@/assets/images/dashboard/customers/login-data.png";
import logs from "@/assets/images/dashboard/customers/logs.png";

export default {
  props: {
    customers: Object,
    tempCustomer: Object,
    customerForm: Object,
    errorLog: Object,
  },
  mixins: [formatThings],
  components: {},
  data() {
    return {
      openCustomerDataModal: false,
      openNotesModal: false,
      openLogsModal: false,
      menu: [
        {
          name: "Dados Pessoais",
          img: user,
          ico: "",
          showItem: true,
          disabled: false,
          action: this.onClickOpenCustomerData,
          extraClass: "",
          alertMsg:
            "Clique para verificar todos os dados obrigatórios do cliente",
        },
        {
          name: "Oportunidades",
          img: opportunity,
          ico: "",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: "",
        },
        {
          name: "Viagens",
          img: travels,
          ico: "",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: "",
        },
        {
          name: "Cartas de Crédito",
          img: creditLetter,
          ico: "",
          showItem: true,
          disabled: !this.$root.isAdmin(),
          action: false,
          extraClass: "",
        },
        {
          name: "Arquivos",
          img: false,
          ico: "paper-clip",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: "",
        },
        {
          name: "Whatsapp",
          img: false,
          ico: "",
          faIco: "fa-brands fa-whatsapp",
          showItem: true,
          disabled: !this.$root.isAdmin(),
          action: false,
          extraClass: "",
        },
        {
          name: "Viajar Wallet",
          img: wallet,
          ico: "",
          showItem: true,
          disabled: true,
          action: false,
          extraClass: "",
        },
        {
          name: "Casos",
          img: cases,
          ico: "",
          showItem: true,
          disabled: true,
          action: false,
          extraClass: "",
        },
        {
          name: "Anotações",
          img: note,
          ico: "",
          showItem: true,
          disabled: false,
          action: this.onClickOpenNotesModal,
          extraClass: " no-border ",
        },
      ],
      submenu: [
        {
          name: "Configurações",
          img: settings,
          ico: "",
          showItem: true,
          disabled: false,
          action: false,
          extraClass: "",
        },
        {
          name: "Dados de Login",
          img: login,
          ico: "",
          showItem: true,
          disabled: true,
          action: false,
          extraClass: "",
        },
        {
          name: "Histórico",
          img: logs,
          ico: "",
          showItem: true,
          disabled: false,
          action: this.onClickOpenLogsModal,
          extraClass: " no-border ",
        },
      ],
    };
  },
  methods: {
    activeTab(tab) {
      this.$emit("activeTab", tab);
    },
  },
};
</script>

<style lang="sass" scoped>
.submenu
  padding: 15px !important
.row
  padding: 5px 10px
  font-size: 13px
  font-weight: 500
  width: 314px
  transition: .3s
  max-width: 100%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  .img
    width: 20px
    display: inline-block
    .anticon
      opacity: 0.45
      font-size: 17px

  &.hover:hover
    background: rgba(0,0,0,0.02)
    color: #ff8000
    .img
      -webkit-filter: grayscale(0)
      -webkit-filter: grayscale(0%)
  &.border
    border-bottom: 1px solid #d6d6d6
    padding: 10px 5px
  &.disabled
    .txt
      opacity: 0.3
  &::last-child
    border-bottom: 0
  &.no-border
    border: 0
    padding: 10px  5px
  &.bold
    font-weight: 600
</style>
